import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Snackbar, Typography } from "@mui/material";
import Textarea from "../../../../Components/TextArea";
import { SendOutlined } from "@mui/icons-material";
import axios from "axios";

const Message = ({ userId }) => {
  const tokenData = JSON.parse(localStorage.getItem("Token"));
  const senderId = tokenData?.userInfo?._id;
  // const { message, sender, onSendMessage } = props
  // const userId = useSelector(state => state.main.userId)
  const bottomEl = useRef(null);
  const [messageContent, setMessageContent] = useState("");
  const [sending, setSending] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  // const sendMessage = () => {
  //   onSendMessage(sender._id, messageContent, setMessageContent, setSending)
  // }
  const handleClick = () => {
    setSnackBarOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };
  const SaveMessage = async () => {
    if (!messageContent.trim()) return;

    setSending(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/messages/`,
        {
          content: messageContent,
          receiverId: userId,
          senderId: senderId,
        }
      );

      if (response.status === 200) {
        console.log("Message sent successfully");
        setMessageContent(""); // Clear message input
        handleClick();
      } else {
        console.log("Error sending message");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setSending(false);
    }
  };
  useEffect(() => {
    if (bottomEl.current) {
      bottomEl.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageContent]);

  return (
    <div
      className="message"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "15px",
            paddingTop: "0px",
          }}
        >
          Write Your Message Here
        </Typography>
        <Textarea
          sx={{
            height: "105px",
            minHeight: "105px",
            maxHeight: "105px",
            width: "100%",
            border: "none",
            borderTop: "1px solid lightGray",
            backgroundColor: "#f5f5f5",
            padding: "10px",
            fontSize: "1rem",
            borderRadius: "8px",
            outline: "none",
          }}
          placeholder="Type a message"
          multiline
          rows={3}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
        />

        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          disabled={!messageContent.trim() || sending}
          onClick={SaveMessage}
        >
          {sending ? (
            <CircularProgress
              size="24px"
              style={{ color: "white", marginRight: "0.75rem" }}
            />
          ) : (
            <SendOutlined style={{ marginRight: "0.75rem" }} />
          )}
          Send
        </Button>
      </div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Message Sent Successfully"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <div ref={bottomEl}></div>
    </div>
  );
};

export default Message;
