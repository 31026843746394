import React, { useEffect, useState } from "react";
import { API_URL, createAxiosInstance } from "../../../../api";
import { Link, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { useSelector } from "react-redux";
import "./ChangePlan.css";

const ChangePlan = () => {
  const { email } = useSelector((state) => state.profile.user);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(""); // New state for subscription status
  const location = useLocation();
  const userId = useSelector((state) => state.profile.user.user);
  console.log(userId);

  useEffect(() => {
    const fetchBillingStatus = async () => {
      setLoading(true); // Show loader while fetching data
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${API_URL}/api/billings/status/${userId}`
        );

        const { membership, price, status } = response.data;

        // Set current plan based on the price (e.g., 15 for Monthly, 150 for Yearly)
        setCurrentPlan(
          price === 2 ? "Monthly Subscription" : "Yearly Subscription"
        );

        // Set subscription status
        setStatus(status);
      } catch (error) {
        console.error("Error fetching billing data:", error);
        SweetAlert.fire({
          title: "Error",
          text: "Failed to load billing status.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } finally {
        setLoading(false); // Hide loader once data is fetched or error occurs
      }
    };

    if (userId) {
      fetchBillingStatus();
    }
  }, [userId]);

  const handleChangePlan = async (planName) => {
    const result = await SweetAlert.fire({
      title: "Change Plan",
      text: `Are you sure you want to switch to the ${planName}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, change plan",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        setLoading(true);
        const response = await createAxiosInstance().post(
          `${API_URL}/api/billings/changeplan/${userId}`,
          { planName }
        );

        SweetAlert.fire({
          title: "Success",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Great",
        });

        setCurrentPlan(planName); // Update the UI to reflect the new plan
      } catch (error) {
        console.error("Error changing plan:", error);
        SweetAlert.fire({
          title: "Error",
          text: error.response?.data?.message || "Failed to change plan.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleReactivate = async (planName, userId) => {
    try {
      const response = await createAxiosInstance().post(
        `${API_URL}/api/billings/reactivate/${userId}`,
        {
          planName,
        }
      );

      if (response.data.success) {
        SweetAlert.fire({
          title: "Success",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        throw new Error(
          response.data.message || "Failed to reactivate subscription."
        );
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      SweetAlert.fire({
        title: "Error",
        text:
          error.response?.data?.message || "Could not reactivate subscription.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="billing-container">
      <div className="billing-menu">
        <Link
          className={`tab${location.pathname === "/billing" ? " active" : ""}`}
          to="/billing"
        >
          My Plan
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/history" ? " active" : ""
          }`}
          to="/billing/history"
        >
          History
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/saved-cards" ? " active" : ""
          }`}
          to="/billing/saved-cards"
        >
          Saved Cards
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/change-plan" ? " active" : ""
          }`}
          to="/billing/change-plan"
        >
          Change Plan
        </Link>
      </div>

      <div className="billing-content">
        <div className="change-plan-container">
          <h2 style={{ textAlign: "center" }}>Change Plan</h2>

          {loading ? (
            <p style={{ textAlign: "center" }}>Loading billing status...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Plan Name</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {[
                  { name: "Monthly Subscription", price: "£2.00/month" },
                  { name: "Yearly Subscription", price: "£24.99/year" },
                ].map((plan) => (
                  <tr key={plan.name}>
                    <td>{plan.name}</td>
                    <td>{plan.price}</td>
                    <td>
                      {currentPlan === plan.name ? (
                        // Handle Current Plan based on status
                        status === "active" ? (
                          <span>Current Plan</span>
                        ) : status === "past_due" ? (
                          <button
                            onClick={() => handleReactivate(plan.name, email)}
                          >
                            Reactivate
                          </button>
                        ) : (
                          <button
                            onClick={() => handleReactivate(plan.name, email)}
                          >
                            Choose Plan
                          </button>
                        )
                      ) : (
                        // Handle Other Plan based on status
                        <button onClick={() => handleChangePlan(plan.name)}>
                          {status === "past_due"
                            ? "Change Plan"
                            : "Choose Plan"}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePlan;
