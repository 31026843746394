import React, { useEffect, useState } from "react";
import { API_URL, createAxiosInstance } from "../../../../api";
import { Link, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import "./SavedCards.css";

const SavedCards = () => {
  const { email } = useSelector((state) => state.profile.user);
  const [savedCards, setSavedCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const userId = useSelector((state) => state.profile.user.user);
  console.log(userId);

  const cardElementStyles = {
    base: {
      backgroundColor: "light-grey",
      fontSize: "18px",
      color: "#32325d",
      "::placeholder": { color: "#aab7c4" },
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "10px",
      marginBottom: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    focus: {
      borderColor: "#1976d2",
    },
  };

  useEffect(() => {
    const fetchSavedCards = async () => {
      if (!userId) return;
      try {
        const response = await createAxiosInstance().get(
          `${API_URL}/api/billings/cards/${userId}`
        );
        setSavedCards(response.data.cards);
      } catch (error) {
        console.error("Error fetching saved cards:", error);
        SweetAlert.fire({
          title: "Error",
          text: "Failed to load saved cards.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    };

    fetchSavedCards();
  }, [userId]);

  // Handle card deletion
  const handleDelete = async (cardId) => {
    const result = await SweetAlert.fire({
      title: "Are you sure?",
      text: "This will delete the card!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await createAxiosInstance().delete(
          `${API_URL}/api/billings/cards/${cardId}/${userId}`
        );
        setSavedCards(savedCards.filter((card) => card.id !== cardId));
        SweetAlert.fire({
          title: "Deleted!",
          text: "The card was removed.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } catch (error) {
        console.error("Error deleting card:", error);
        SweetAlert.fire({
          title: "Error",
          text: "Failed to delete the card.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  // Handle adding a new card
  const handleAddCard = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      SweetAlert.fire({
        title: "Error",
        text: "Stripe is not initialized. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);

    // Create payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: { name: cardholderName },
    });

    if (error) {
      SweetAlert.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
      setLoading(false);
      return;
    }

    // Sending the payment method to backend for saving
    try {
      const response = await createAxiosInstance().post(
        `${API_URL}/api/billings/cards/${userId}`, // Adjusted endpoint to match your route
        { paymentMethodId: paymentMethod.id }
      );

      SweetAlert.fire({
        title: "Success",
        text: "Your card was added successfully!",
        icon: "success",
        confirmButtonText: "Great",
      });

      setSavedCards((prevCards) => [...prevCards, response.data.card]);
    } catch (error) {
      console.error("Error saving card:", error);
      SweetAlert.fire({
        title: "Error",
        text: "There was an error saving your card. Please try again.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    setLoading(false);
  };

  return (
    <div className="billing-container">
      <div className="billing-menu">
        <Link
          className={`tab${location.pathname === "/billing" ? " active" : ""}`}
          to="/billing"
        >
          My Plan
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/history" ? " active" : ""
          }`}
          to="/billing/history"
        >
          History
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/saved-cards" ? " active" : ""
          }`}
          to="/billing/saved-cards"
        >
          Saved Cards
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/change-plan" ? " active" : ""
          }`}
          to="/billing/change-plan"
        >
          Change Plan
        </Link>
      </div>

      <div className="billing-content">
        <div className="saved-cards-container">
          <h2 style={{ textAlign: "center" }}>Saved Cards</h2>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Card Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {savedCards.map((card) => (
                <tr key={card.id}>
                  <td>{card.billing_details?.name || "N/A"}</td>
                  <td>
                    {card.card?.brand?.toUpperCase() || "Unknown"} ending in{" "}
                    {card.card?.last4}
                  </td>
                  <td>
                    <button onClick={() => handleDelete(card.id)}>
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <form onSubmit={handleAddCard} className="add-card-form">
            <h3 style={{ textAlign: "center", marginTop: "40px" }}>
              Add a New Card
            </h3>

            <div className="form-group">
              <input
                type="text"
                id="cardholder-name"
                placeholder="Full Name"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
                required
              />
            </div>
            <div className="card-input">
              <div className="card-number-row">
                <CardNumberElement options={{ style: cardElementStyles }} />
              </div>
              <div className="expiry-cvc-row">
                <CardExpiryElement options={{ style: cardElementStyles }} />
                <CardCvcElement options={{ style: cardElementStyles }} />
              </div>
            </div>
            <button
              type="submit"
              disabled={!stripe || loading}
              className="submit-button addcard-btn"
            >
              {loading ? "Adding..." : "Add Card"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SavedCards;
