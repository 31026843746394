import React, { useState, useEffect, useContext } from "react";
import "./CourseBilling.css";
import CircleSvg from "../../assets/images/circle-container.svg";
import { TextField, InputAdornment, Checkbox } from "@mui/material";
import { CreditCard } from "@mui/icons-material";
import { IMaskInput } from "react-imask";
import CountrySelect from "../../Components/CountrySelect";
import { LoadingButton } from "@mui/lab";
import { createAxiosInstance } from "../../api";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { showErrorMessage } from "../../utils";
import { CreateCourseContext } from "../AddCourse/AddCourseContext/AddContextContext";
const TextMaskCustom0 = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#### #### #### ####"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="##/##"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

const TextMaskCustom1 = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="###"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

const CourseBilling = ({ advancePrice, duration }) => {
  const { setsendReq, sendReq, coursePrice, setCoursePrice } =
    useContext(CreateCourseContext);
  const [error, setError] = useState({});
  const [name, setName] = useState("");
  const [card, setCard] = useState("");
  const [expire, setExpire] = useState("");
  const [cvc, setCvc] = useState("");
  const [country, setCountry] = useState("GB");
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(coursePrice);

  const tokenData = JSON.parse(localStorage.getItem("Token"));
  const userId = tokenData?.userInfo?._id;

  useEffect(() => {
    if (coursePrice) {
      const numericValue = parseFloat(coursePrice.replace("£", "")) * 100;
      setPrice(Math.round(numericValue));
    }
  }, [coursePrice]);

  useEffect(() => {
    console.log("price", price);
  }, [price]);

  useEffect(() => {
    console.log("SetSendReq", setsendReq);
  }, [setsendReq]);

  const handleError = () => {
    let newError = {};
    const body = {
      card: card.replace(/ /g, ""),
      expire: expire.replace(/\//g, ""),
      cvc,
    };
    card.replace(/ /g, "");
    if (name.trim().length === 0) newError.name = "Please input your name";
    if (body.card.trim().length !== 16)
      newError.card = "Please input valid card number";
    if (body.card.trim().length === 0) newError.card = "Please input your name";
    if (body.expire.trim().length === 0)
      newError.expire = "Please input your name";
    else if (body.expire.trim().length !== 4)
      newError.expire = "Please input valid value";
    else {
      // Get the current date
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // January is represented by 0, so we add 1 to get the actual month number
      const currentYear = currentDate.getFullYear();

      // Convert the expire value into month and year
      const expire_month = parseInt(body.expire.trim().slice(0, 2));
      const expire_year = 2000 + parseInt(body.expire.trim().slice(2));

      // Check if the expiration date is before the current date
      const isExpired =
        expire_year < currentYear ||
        (expire_year === currentYear && expire_month < currentMonth);

      if (expire_month < 1 || expire_month > 12 || isExpired)
        newError.expire = "Please input valid value";
    }
    if (body.cvc.trim().length < 3) newError.cvc = "Please input valid value";
    if (body.cvc.trim().length === 0) newError.cvc = "Please input your name";
    setError(newError);
    if (Object.keys(newError).length > 0) return null;
    return body;
  };

  const navigate = useNavigate();

  const handleClick = () => {
    const body = handleError();
    if (!body) {
      showErrorMessage("");
      return;
    }
    setLoading(true);
    const expire_month = parseInt(body.expire.trim().slice(0, 2));
    const expire_year = 2000 + parseInt(body.expire.trim().slice(2));
    window.Stripe.card.createToken(
      {
        number: body.card,
        exp_month: expire_month,
        exp_year: expire_year,
        cvc: body.cvc,
      },
      async (status, response) => {
        if (status === 200) {
          await charge(response);
        }
        setLoading(false);
      }
    );
  };

  const charge = async (cardResponse) => {
    return createAxiosInstance()
      .post("/api/billings/", { token: cardResponse?.id, userId, coursePrice })
      .then(() => {
        SweetAlert.fire({
          // position: 'top-end',
          imageUrl: "/assets/success-icon.png", // https://pixlok.com/wp-content/uploads/2021/12/Green-Tick-Icon-SVG-03vd.png
          imageHeight: "100px",
          title: "Checkout successful!",
          // html: 'Your account will be active for one year!',
          width: 600,
          confirmButtonColor: "#0099FF",
          customClass: {
            icon: "no-before-icon",
          },
        });
        setsendReq(true);
      })
      .catch((err) => {
        console.log(err);
        SweetAlert.fire({
          imageUrl: "/assets/error-icon.png", // https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png
          imageHeight: "100px",
          title: "Checkout failed",
          text:
            err.response?.data?.message || "Please input valid information.",
          confirmButtonColor: "#0099FF",
        });
      });
  };

  useEffect(() => {
    loadStripe();
  }, []);

  const loadStripe = () => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(
          "pk_test_51PiVILRuyWvYsE9N2L4wsSeCwrIaWMMc6m4af4ZizxviRXDhSzkhYSlFMZND7tCxFAxYFaxAKvkb3d69iVObOQ7v00qIDnqvoG"
        );
      };
      window.document.body.appendChild(s);
    }
  };

  return (
    <div className="billing-container">
      <div className="page-title">Advertisement payment</div>
      <p
        style={{
          width: "100%",
          padding: "32px",
          textAlign: "center",
          margin: 0,
        }}
      >
        By paying for your advertisement, it will be reviewed by the admin. Once
        approved, it will be displayed on the website for parents and students
        to see. You will be notified by email once the advertisement goes live.
      </p>
      <div className="main-box">
        <div className="billing-description">
          <div className="description-main">
            <div className="description-head">Advertisement Payment</div>
            <div className="description-body">
              <img
                src={CircleSvg}
                alt="circle-container"
                className="circle-container"
              />
              <div className="description-center">
                <div>
                  {/* <p>For teacher</p> */}
                  <p className="dollar-28">{advancePrice}</p>
                  <p className="per-year">
                    {duration === "12"
                      ? "1 Year"
                      : duration === "1"
                      ? "1 Month"
                      : `${duration} Months`}
                  </p>
                </div>
              </div>
            </div>
            <img
              src="/assets/images/secure-payments.png"
              alt="Secure logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div style={{ flex: 6 }}>
          <div className="form">
            <div className="form-control">
              <div className="label">Card holder name</div>
              <TextField
                variant="outlined"
                hiddenLabel
                style={{ margin: "0" }}
                onChange={(e) => setName(e.target.value)}
                placeholder="First and last name"
                error={!!error["name"]}
                value={name}
                fullWidth
                id="billing-name"
              />
            </div>
            <div className="form-control">
              <div className="label">Price</div>
              <TextField
                variant="outlined"
                hiddenLabel
                style={{ margin: "0" }}
                value={
                  duration === 1
                    ? `1 month (${advancePrice})`
                    : duration === 12
                    ? `1 year (£${advancePrice})`
                    : `${duration} months (£${advancePrice})`
                }
                fullWidth
                id="billing-name"
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className="form-control">
              <div className="label">Card details</div>
              <TextField
                variant="outlined"
                hiddenLabel
                style={{ margin: "0" }}
                onChange={(e) => setCard(e.target.value)}
                placeholder="Card number"
                error={!!error["card"]}
                value={card}
                fullWidth
                id="billing-card"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCard />
                    </InputAdornment>
                  ),
                  inputComponent: TextMaskCustom0,
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  gap: "1rem",
                }}
              >
                <TextField
                  variant="outlined"
                  hiddenLabel
                  style={{ margin: "0", flex: 1 }}
                  onChange={(e) => setExpire(e.target.value)}
                  placeholder="MM/YY"
                  error={!!error["expire"]}
                  value={expire}
                  id="billing-card"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
                <TextField
                  variant="outlined"
                  hiddenLabel
                  style={{ margin: "0", flex: 1 }}
                  onChange={(e) => setCvc(e.target.value)}
                  placeholder="cvc"
                  error={!!error["cvc"]}
                  value={cvc}
                  id="billing-card"
                  InputProps={{
                    inputComponent: TextMaskCustom1,
                  }}
                />
              </div>
            </div>
            <div className="form-control">
              <div className="label">Country</div>
              <CountrySelect
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            {/* <div className="form-control plan">
            <p className="per-year">£19.99 per year</p>
            <p className="tutor-plan">Tutor Plan</p>
          </div> */}
            <div className="form-control">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Checkbox
                  onChange={(e) => setAccept(e.target.checked)}
                  value={accept}
                />
                <span className="accept">
                  I accept the&nbsp;
                  <span className="term" onClick={() => navigate("/terms")}>
                    terms and conditions
                  </span>
                </span>
              </div>
            </div>
            <div className="form-control">
              <LoadingButton
                onClick={handleClick}
                loading={loading}
                variant="contained"
                disabled={!accept}
                size="large"
              >
                <span>Subscribe</span>
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseBilling;
