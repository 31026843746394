import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { CoursesNavbar } from "./Components/Navbar/Navbar";
import { Filter } from "./Components/filter/filter";
import { CourseCard } from "./Components/CourseCard/CourseCard";
import { SearchBars } from "./Components/SearchBars/SearchBars";
import { useParams } from "react-router-dom";

export const DisplayCourses = () => {
  const [id, setId] = useState("");
  const paramsId = useParams();
  useEffect(() => {
    if (paramsId) {
      setId(paramsId);
    } else {
      setId(null);
    }
  }, [paramsId]);
  return (
    <Box>
      <Box>
        <CoursesNavbar />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "15px",
          }}
        >
          <SearchBars />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "center",
            sm: "center",
            md: "flex-start",
          },
          gap: 2,
        }}
      >
        <Filter />
        <CourseCard idd={id} />
      </Box>
      <Box></Box>
    </Box>
  );
};
