import React, { useEffect, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../../Dashboard/Components/Messages/Message";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import Snackbar from "@mui/material/Snackbar";
import {
  faBook,
  faCalendar,
  faCopy,
  faEdit,
  faEnvelope,
  faFlag,
  faGlobe,
  faMapMarker,
  faMoneyBill,
  faPhone,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export const CourseDetails = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { id } = useParams();

  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  let imageUrl;
  let pdfUrl;
  const handleClick = () => {
    setSnackBarOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };
  useEffect(() => {
    fetchCourses(id);
  }, [id]);

  useEffect(() => {
    pdfUrl = courses[0]?.file
      ? `${process.env.REACT_APP_SERVER}/${courses[0]?.file}`
      : `${process.env.REACT_APP_SERVER}/default-file.pdf`;
    imageUrl = courses[0]?.image
      ? `${process.env.REACT_APP_SERVER}/${courses[0]?.image}`
      : `${process.env.REACT_APP_SERVER}/default-image.jpg`;
  }, [courses]);

  const handleMessageModalOpen = () => {
    setMessageModalOpen(true);
  };
  const handleShareModalOpen = () => {
    setShareModalOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };
  const navigteEdit = () => {
    navigate(`/course/new/${id}`);
  };
  const copyToClipboard = () => {
    const courseId = courses._id;
    const url = `${process.env.REACT_APP_URL}/course-detail/${id}`;
    navigator.clipboard.writeText(url).then(
      function () {
        handleClick();
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };
  const handleFacebookShare = () => {
    const courseId = courses._id;
    const url = `${process.env.REACT_APP_URL}/course-detail/${id}`;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const handleWhatsAppShare = () => {
    const courseId = courses._id;
    const url = `${process.env.REACT_APP_URL}/course-detail/${id}`;
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      "_blank"
    );
  };
  const handleMessageModalClose = () => {
    setMessageModalOpen(false);
  };

  const fetchCourses = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/courses/${id}`
      );
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  function onError(e) {
    // e.target.src = placeholder;
  }
  console.log("userId ", userId);
  return (
    <Box sx={{ paddingLeft: { xs: "0px", sm: "0px", md: "0px", lg: "40px" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            textAlign: "left",
            marginBottom: "20px",
            marginLeft: "10px",
            paddingTop: "4vh",
          }}
        >
          {courses[0]?.name} - {courses[0]?.level}
        </Typography>
        {userId && userId !== "null" && (
          <Box sx={{ pr: "20px" }}>
            <Button
              sx={{
                backgroundColor: "#1976d2",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "28px",
                marginTop: "20px",
                width: { xs: "30vw", sm: "20vw", md: "7vw", lg: "8vw" },
                "&:hover": {
                  backgroundColor: "#609BDF",
                },
              }}
              onClick={navigteEdit}
            >
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
              Edit Course
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: { xs: "center", sm: "center", md: "flex-start" },
          justifyContent: { xs: "center", sm: "center", md: "flex-start" },
        }}
      >
        <img
          src={`${process.env.REACT_APP_SERVER}/${
            courses[0]?.image ?? "default-image.jpg"
          }`}
          alt="courseimage"
          onError={(e) => onError(e)}
          style={{
            height: "350px",
            objectFit: "cover",
            paddingTop: "10px",
            paddingLeft: "10px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "@media (min-width:600px)": { width: "auto !important" },
            "@media (min-width:900px)": { width: "auto !important" },
            paddingLeft: { xs: 0, sm: "24vw", md: "3vw" },
            paddingTop: { xs: "3vh", sm: "3vh", md: "0" },
            textAlign: { xs: "center", sm: "center", md: "left" },
            gap: "10px",
          }}
        >
          {courses[0]?.by && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faUser}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {courses[0].by}
              </span>
            </Typography>
          )}

          {courses[0]?.name && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faBook}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {courses[0].name}
              </span>
            </Typography>
          )}

          {courses[0]?.coursePrice && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faMoneyBill}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {courses[0].coursePrice}
              </span>
            </Typography>
          )}
          {courses[0]?.email && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {courses[0].email}
              </span>
            </Typography>
          )}

          {courses[0]?.level && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faFlag}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {courses[0].level}
              </span>
            </Typography>
          )}

          {courses[0]?.isOngoing ? (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                Ongoing
              </span>
            </Typography>
          ) : (
            courses[0]?.date?.length && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{ marginRight: "15px", color: "#1976d2" }}
                />
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {courses?.[0]?.date[0]?.split("T")?.[0]} -{" "}
                  {courses?.[0]?.date[1]?.split("T")?.[0]}
                </span>
              </Typography>
            )
          )}

          {courses[0]?.types && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faMapMarker}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ fontWeight: "bolder" }}>
                {courses[0]?.types[0] &&
                  courses[0]?.types[0]?.charAt(0).toUpperCase() +
                    courses[0]?.types[0]?.slice(1)}
                {courses[0]?.types[0] && courses[0]?.types[1] && " / "}
                {courses[0]?.types[1] &&
                  courses[0]?.types[1]?.charAt(0).toUpperCase() +
                    courses[0]?.types[1]?.slice(1)}
              </span>
            </Typography>
          )}

          {courses[0]?.telephone && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faPhone}
                style={{ marginRight: "6px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {" "}
                {courses[0].telephone}
              </span>
            </Typography>
          )}

          {courses[0]?.addOne &&
            courses[0]?.addTwo &&
            courses[0]?.city &&
            courses[0]?.town && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <FontAwesomeIcon
                  icon={faMapMarker}
                  style={{ marginRight: "15px", color: "#1976d2" }}
                />
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {" "}
                  {courses[0].addOne}, {courses[0].addTwo}, {courses[0].city},{" "}
                  {courses[0].addTwo}
                </span>
              </Typography>
            )}

          {courses[0]?.socialLink && (
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <FontAwesomeIcon
                icon={faGlobe}
                style={{ marginRight: "15px", color: "#1976d2" }}
              />
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {courses[0].socialLink}
              </span>
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {userId && userId !== "null" && (
              <Button
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  padding: "10px 20px",
                  borderRadius: "28px",
                  width: { xs: "30vw", sm: "20vw", md: "7vw", lg: "8vw" },
                  "&:hover": {
                    backgroundColor: "#609BDF",
                  },
                }}
                onClick={handleMessageModalOpen}
              >
                Message
              </Button>
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#609BDF",
                  borderRadius: "28px",
                  border: "1px solid",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                },
              }}
              onClick={handleShareModalOpen}
            >
              <img
                src="/assets/images/share.png"
                alt="share img"
                style={{ width: "25px" }}
              />
              <p style={{ fontSize: "15px" }}>Share</p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "90%", maxWidth: "600px", overflowWrap: "break-word" }}>
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "5vh",
            fontSize: "20px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
          dangerouslySetInnerHTML={{ __html: courses[0]?.description }}
        />

        {/* <Typography
                    sx={{
                        paddingLeft: '10px',
                        paddingTop: '5vh',
                        fontSize: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal'
                    }}
                >
                    {courses[0]?.description}
                </Typography> */}
      </Box>

      <Modal
        open={messageModalOpen}
        onClose={handleMessageModalClose}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: { xs: "39%", sm: "35%", md: "35%", lg: "32%" },
            overflowY: "auto",
          }}
        >
          <Typography
            onClick={handleMessageModalClose}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Typography>
          <Message userId={courses[0]?.userId} />
        </Box>
      </Modal>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Link Copied to Clickboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Modal
        open={shareModalOpen}
        onClose={handleShareModalClose}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                cursor: "pointer",
                mb: 2,
              }}
            >
              <Typography
                id="share-modal-title"
                variant="h6"
                sx={{ mb: 2, fontWeight: "bolder", fontSize: "2rem" }}
              >
                Share This Course
              </Typography>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontSize: "2rem" }}
                onClick={handleShareModalClose}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Button
                onClick={() => handleWhatsAppShare()}
                sx={{
                  padding: "10px",
                  fontSize: "2rem",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "#e0f7fa",
                  border: "2px solid #2196f3",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              >
                <WhatsAppIcon sx={{ fontSize: "2rem", color: "black" }} />
              </Button>

              <Button
                onClick={() => handleFacebookShare()}
                sx={{
                  padding: "10px",
                  fontSize: "2rem",
                  backgroundColor: "#e0f7fa",
                  border: "2px solid #2196f3",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              >
                <FacebookSharpIcon sx={{ fontSize: "2rem", color: "black" }} />
              </Button>

              <Button
                onClick={() => copyToClipboard()}
                sx={{
                  padding: "7px",
                  fontSize: "2rem",
                  backgroundColor: "#e0f7fa",
                  border: "2px solid #2196f3",
                  borderRadius: "50%",
                }}
              >
                <FontAwesomeIcon
                  icon={faCopy}
                  style={{ fontSize: "2rem", color: "black" }}
                />
              </Button>
            </Box>
            <Box sx={{ paddingTop: "25px", width: "100%" }}>
              <TextField
                type="text"
                placeholder={`${process.env.REACT_APP_URL}/course-detail/${id}`}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    fontSize: "1rem",
                    padding: "10px",
                    height: "5vh",
                  },
                }}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2196f3",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "black",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "black",
                    opacity: 1,
                  },
                }}
              />
              <Box
                sx={{
                  paddingTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "#2196f3",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    // textTransform: "uppercase",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#1976d2",
                    },
                    "&:disabled": {
                      backgroundColor: "#b0bec5",
                      color: "#ffffff",
                    },
                  }}
                  onClick={copyToClipboard}
                >
                  Copy!
                </Button>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
    </Box>
  );
};
