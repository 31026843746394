import { useContext } from "react";
import { Box, TextField, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { FilterContext } from "../FilterContext/FilterContext";

export const SearchBars = () => {
  const { searchName, setSearchName, paymentMethod, setPaymentMethod } = useContext(FilterContext);

  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
  };

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        gap: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' }
        }}
      >
        <TextField
          label="Search by keywords"
          variant="outlined"
          value={searchName}
          onChange={handleSearchNameChange}
          fullWidth
          sx={{
            width: { lg: '800px', md: '500px', sm: '350px', xs: '250px' },
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
              backgroundColor: '#fff',
            },
          }}
        />
        <Box sx={{ }}>
        <FormControl component="fieldset">
          <FormControlLabel
          sx={{ '& .MuiFormControlLabel-label': { marginLeft: '10px', }, '& .MuiCheckbox-root .MuiSvgIcon-root': { backgroundColor: '#fff', },
        
          }}
            control={
              <Checkbox
                checked={paymentMethod.includes("free")}
                onChange={handlePaymentChange}
                value="free"
              />
            }
            label="Free"
          />
          <FormControlLabel
          sx={{ '& .MuiFormControlLabel-label': { marginLeft: '10px' }, '& .MuiCheckbox-root .MuiSvgIcon-root': { backgroundColor: '#fff', }  }}
            control={
              <Checkbox
                checked={paymentMethod.includes("paid")}
                onChange={handlePaymentChange}
                value="paid"
              />
            }
            label="Paid"
          />
        </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
