import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CoursesNavbar = () => {
  const navigate = useNavigate();

  const addCourses = () => {
    navigate('/course/new');
  }

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
          fontSize: '1.5rem'
        }}
      >
        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', color: '#1976d2', paddingTop: '5px' }}>
          <Typography sx={{ fontSize: '2.5rem' }}>Courses</Typography>
        </Box>
        <Box style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', paddingTop: '10px', paddingRight: "50px" }} onClick={addCourses}>
          <Button sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '28px',
            '&:hover': {
              backgroundColor: '#609BDF',
            }
          }}>
            + Add Course
          </Button>
        </Box>
      </Box>
      <Box>
        <hr style={{ width: '78%', borderTop: '1px solid #ccc', marginTop: '10px', marginRight: '65px' }} />
      </Box>
    </Box>
  );
}
