import config from "config";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL, createAxiosInstance } from "../../../api";

const ProfileStepInformationStatuses = () => {
  const navigate = useNavigate();
  const [billingStatus, setBillingStatus] = useState("false"); // Local state for billing status

  // Get profile data from Redux
  const {
    avatar,
    about,
    price,
    qualifications,
    title,
    online,
    faceToFace,
    gender,
    isAgency,
    links,
    publish,
    email,
    phone,
    exam,
    level,
    _id,
    status,
  } = useSelector((state) => state.profile.user);

  const userId = useSelector((state) => state.profile.user.user);
  console.log("payment status is:", billingStatus);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!userId) {
        console.log("User ID is undefined or invalid. Skipping payment check.");
        return; // Exit the function early if the userId is not valid
      }

      try {
        console.log("Checking payment status for user:", userId);

        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${API_URL}/api/billings/hasPaid/${userId}`
        );

        console.log("Payment status response:", response.data);

        if (response.data && response.data.paid) {
          console.log("User has:", response.data.paid);

          setBillingStatus(response.data.paid);
        } else {
          console.log("User has not paid.");

          setBillingStatus("false");
        }
      } catch (error) {
        console.error(
          "Error checking payment status:",
          error.response?.data?.message || error.message
        );
        setBillingStatus("false");
      }
    };

    checkPaymentStatus();
  }, [userId]); // Re-run the effect when `userId` changes

  return (
    <>
      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=0")}
        >
          <strong>{config["profile-step-0-title"]}</strong>
        </span>
        {Object.keys(level).some((k) => level[k]) ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=1")}
        >
          <strong>What exam board can you teach?</strong>
          <span style={{ color: "#929292", fontSize: "16px" }}>
            {" "}
            (Optional)
          </span>
        </span>
        {Object.keys(exam).some((k) => exam[k]) ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span
            className={"profile-cat-not-done"}
            style={{ color: "lightgray" }}
          >
            <hr
              style={{
                width: "94px",
                borderColor: "lightgray",
                border: "none",
                margin: "14px 0px",
                height: "1.2px",
                backgroundColor: "lightgray",
              }}
            />
          </span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=2")}
        >
          <strong>Select your price and tutoring options</strong>
        </span>
        {online || faceToFace ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=3")}
        >
          <strong>Make Payment for Subscription</strong>
        </span>
        {billingStatus !== "false" ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=4")}
        >
          <strong>Create your tutor profile</strong>
        </span>
        {isAgency !== -1 &&
        title &&
        gender !== -1 &&
        publish !== -1 &&
        about &&
        about !== "<p><br></p>" &&
        avatar ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=5")}
        >
          <strong>Tell us about your qualifications</strong>
        </span>
        {Object.keys(qualifications).some((k) => qualifications[k] !== -1) ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=6")}
        >
          <strong>How do clients get in touch with you?</strong>
          <span style={{ color: "#929292", fontSize: "16px" }}>(Optional)</span>
        </span>
        {email || phone ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>
            <hr
              style={{
                width: "94px",
                borderColor: "lightgray",
                border: "none",
                margin: "14px 0px",
                height: "1.2px",
                backgroundColor: "lightgray",
              }}
            />
          </span>
        )}
      </div>
    </>
  );
};

export default ProfileStepInformationStatuses;
