import React from "react";
import "./ProgressBar.css";

const ProgressBar = ({ step }) => {
  // Grouping steps for simplicity
  const groupedSteps = [
    "Basic Info",
    "Subjects & Prices",
    "Subscription",
    "Verification",
    "Availability",
  ];

  const totalSteps = groupedSteps.length;
  const progressPercentage = ((step - 1) / (totalSteps - 1)) * 100;

  return (
    <div className="progress-bar-container">
      {/* Progress bar */}
      <div className="progress-bar">
        <div
          className="progress-bar-filled"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>

      {/* Step indicators */}
      <div className="progress-bar-steps">
        {groupedSteps.map((label, index) => (
          <div
            key={index}
            className={`progress-step ${index + 1 <= step ? "completed" : ""}`}
          >
            <div className="step-circle">
              {index + 1 < step ? "✓" : index + 1}
            </div>
            <div className="step-label">{label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
