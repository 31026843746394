import React, { createContext, useState } from "react";


export const FilterContext = createContext();

export const FilterProvider = ({children}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  return (
    <FilterContext.Provider
     value={{
      selectedFilters,
      setSelectedFilters,
      searchName,
      setSearchName,
      searchLocation,
      setSearchLocation,
      paymentMethod,
      setPaymentMethod,
     }}
    > 
    {children}
    </FilterContext.Provider>
  )
}