import React, { useEffect, useState, useContext } from "react";
import { API_URL, createAxiosInstance } from "../../../../api";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const BillingHistory = () => {
  const [billingHistory, setBillingHistory] = useState([]);
  const location = useLocation();
  const { email } = useSelector((state) => state.profile.user);
  const userId = useSelector((state) => state.profile.user.user);
  console.log(userId);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      if (!userId) return; // Ensure user email is available
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${API_URL}/api/billings/history/${userId}`
        );
        setBillingHistory(response.data.history);
      } catch (err) {
        console.error("Error fetching billing history:", err);
      }
    };

    fetchBillingHistory();
  }, [email]);

  return (
    <div className="billing-container">
      <div className="billing-menu">
        <Link
          className={
            "tab" + (location.pathname === "/billing" ? " active" : "")
          }
          to="/billing"
        >
          My Plan
        </Link>
        <Link
          className={
            "tab" + (location.pathname === "/billing/history" ? " active" : "")
          }
          to="/billing/history"
        >
          History
        </Link>
        <Link
          className={
            "tab" +
            (location.pathname === "/billing/saved-cards" ? " active" : "")
          }
          to="/billing/saved-cards"
        >
          Saved Cards
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/change-plan" ? " active" : ""
          }`}
          to="/billing/change-plan"
        >
          Change Plan
        </Link>
      </div>

      <div className="billing-content">
        <div className="billing-history-section">
          <h1 className="page-title">Billing History</h1>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory.map((entry, index) => (
                <tr key={index}>
                  <td>{new Date(entry.periodStart).toLocaleDateString()}</td>
                  <td>£{entry.amountPaid}</td>
                  <td>{entry.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;
