import React from "react";
import Home from "./Home/Home";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import AboutUs from './AboutUs';
// import LinearProgress from '@mui/material/LinearProgress';
import SignUp from "./Signup/SignUp";
import SignIn from "./Signin/SignIn";
import Profile from "./Profile/Profile";
import HowItWorks from "./HowItWorks/HowItWorks";
import Search from "./Search/Search";
import Contact from "./Contact/Contact";
import TutorView from "./TutorView/TutorView";
import DashBoard from "./Dashboard/Dashboard";
import { useSelector } from "react-redux";
import Billing from "./Billing/Billing";
import SavedCards from "./Billing/Components/SavedCards/SavedCards"; // Correct import path
import BillingHistory from "./Billing/Components/BillingHistory/BillingHistory"; // Correct import path
import ChangePlan from "./Billing/Components/ChangePlan/ChangePlan";
import Terms from "./Terms";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Cookies from "./Cookies";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Course from "./Course/Course";
import AddCourse from "./AddCourse/AddCourse";
import CourseBilling from "./CourseBilling/CourseBilling";
import { DisplayCourses } from "./DisplayCourses/DisplayCourses";
import { CourseDetails } from "./DisplayCourses/CourseDetails/CourseDetails";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(stripePublicKey); // Replace with your actual Stripe publishable key

const ProtectedRoute = ({ children, redirect }) => {
  const isAuth = useSelector((state) => !!state.main.userId);

  return isAuth ? (
    children
  ) : (
    <Navigate
      to={{ pathname: "/signin", search: `?redirect=${redirect}` }}
      replace
    />
  );
};

// ... Rest of your code
const Routing = () => {
  // const cookieValue = Cookies.get('token');
  const isAuth = useSelector((state) => !!state.main.userId);
  const location = useLocation();
  const currentURL = location.pathname + location.search;

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/howitworks" element={<HowItWorks />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute redirect={currentURL}>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route path="/search" element={<Search />} />
      <Route path="/tutor/:tutorId" element={<TutorView />} />
      <Route path="/contact" element={<Contact />} />
      {/*<Route path="/about" element={<AboutUs />} />*/}
      <Route path="/terms" element={<Terms />} />
      <Route path="/cookies-policy" element={<Cookies />} />
      <Route path="/course" element={<Course />} />
      <Route path="/course/new" element={<AddCourse />} />
      <Route path="/course/new/:id" element={<AddCourse />} />
      <Route path="/courses" element={<DisplayCourses />} />
      <Route path="/courses/:id" element={<DisplayCourses />} />
      <Route path="/course/new/billing" element={<CourseBilling />} />
      <Route path="/course-detail/:id" element={<CourseDetails />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute redirect={currentURL}>
            <DashBoard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/billing"
        element={
          <ProtectedRoute isAuthenticated={isAuth} redirect={currentURL}>
            <Billing />
          </ProtectedRoute>
        }
      />

      <Route
        path="/billing/saved-cards"
        element={
          <ProtectedRoute isAuthenticated={isAuth} redirect={currentURL}>
            <Elements stripe={stripePromise}>
              <SavedCards />
            </Elements>
          </ProtectedRoute>
        }
      />

      <Route
        path="/billing/history"
        element={
          <ProtectedRoute isAuthenticated={isAuth} redirect={currentURL}>
            <BillingHistory />
          </ProtectedRoute>
        }
      />

      <Route
        path="/billing/change-plan"
        element={
          <ProtectedRoute isAuthenticated={isAuth} redirect={currentURL}>
            <Elements stripe={stripePromise}>
              <ChangePlan />
            </Elements>
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Routing;
