import './App.css';
import React, { useEffect } from 'react';
import Footer from './Pages/Footer/Footer';
import Navbar from './Pages/Navbar/Navbar';
import Routing from './Pages/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { syncDatabaseUser } from './store/main';
import { getMyProfile, getMyMessages, getGuestProfile } from './store/setProfile';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { connectSocket, socket } from './socket';
import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import { FilterProvider } from './Pages/DisplayCourses/Components/FilterContext/FilterContext';
import { CourseContext } from './Pages/AddCourse/AddCourseContext/AddContextContext';

function App() {
  const isAuth = useSelector(state => !!state.main.userId);
  const isGuest = useSelector(state => state.main.isGuest);
  const dispatch = useDispatch();

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    const token = localStorage.getItem('mathsAuthToken');
    if (token) {
      dispatch(syncDatabaseUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      if (isGuest) {
        dispatch(getGuestProfile());
      }
      dispatch(getMyProfile());
      dispatch(getMyMessages());
      connectSocket();
      if (socket) {
        socket.on('message', (message) => {
          dispatch(getMyMessages());
        });
      }
    } else {
      if (socket) {
        socket.disconnect();
      }
    }
  }, [isAuth, isGuest, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <FilterProvider>
        <CourseContext>
          <div className="App">
            <nav>
              <Navbar />
            </nav>
            <main className="header-component">
              <Routing />
            </main>
            <footer>
              <Footer />
            </footer>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </CourseContext>
      </FilterProvider>
    </ThemeProvider>
  );
}

export default App;
