import React, {useCallback, useEffect, useState} from 'react';
import config from 'config';
import './home.css';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setFilters} from '../../store/search';
// import { getPositionFromPostcode } from '../../utils'
import SweetAlert from 'sweetalert2';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import AcrossCard from './Components/AcrossCard';
import {createAxiosInstance} from '../../api';
import {syncDatabaseUser} from '../../store/main';
import {ReactComponent as UnderCurve} from '../../assets/images/undercurve.tsx';
import {showErrorMessage} from '../../utils';
import Tour from 'reactour';

// DM update
import ContentRows from './Components/ContentRows';

const Home = () => {
  const isGuest = useSelector((state) => state.main.isGuest);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const courses = () =>{
    navigate('/courses')
  }
  const smallScreen = useMediaQuery("(max-width: 950px)");
  const homeStepsForTutor = [
    // {
    //   selector: ".search-box",
    //   content: (
    //     <div style={{ marginTop: "8px" }}>
    //       Use the search bar to filter down the type of maths tutor you are
    //       looking for.
    //     </div>
    //   ),
    // },
    {
      selector: smallScreen ? ".explore-more-mobile" : ".explore-more",
      content: (
        <div style={{ marginTop: "8px" }}>
          To create your profile, navigate to the <strong>Dashboard</strong>,
          select the <strong>Create profile</strong> button.
        </div>
      ),
    },
  ];
  const homeStepsForGuest = [
    {
      selector: ".search-box",
      content: (
        <div style={{ marginTop: "8px" }}>
          Use the search bar to filter down the type of maths tutor you are
          looking for.
        </div>
      ),
    },
  ];
  const dispatch = useDispatch();
  const [level, setLevel] = useState(null);
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const mainUser = useSelector((state) => state.main);

  // const [location, setLocation] = useState(-1);
  // const [postcode, setPostcode] = useState('');
  // const [postcodeError, setPostcodeError] = useState(false)
  const handleLevelChange = (e) => {
    setLevel(e.target.value);
  };
  // const handleLocationChange = (e) => {
  //   setLocation(e.target.value)
  // }
  const navigate = useNavigate();
  const search = async () => {
    const defaultFilters = {
      level: null,
      distance: 7,
      gender: -1,
      isOnline: -1,
      price: [15, 100],
      latitude: -1,
      longitude: -1,
      load: 1,
    };
    if (level) defaultFilters["level"] = level;
    // if (location > -1) {
    //   if (location === 0) {
    //     defaultFilters['isOnline'] = 1
    //   } else {
    //     defaultFilters['isOnline'] = 0
    //     defaultFilters['distance'] = 10 * (location - 1)
    //     if (postcode.trim() === '') {
    //       setPostcodeError(true)
    //       return
    //     } else {
    //       setPostcodeError(false)
    //       defaultFilters["postcode"] = postcode.trim()
    //       const isPostcodeValid = await getPositionFromPostcode(defaultFilters["postcode"])
    //       if (isPostcodeValid.ok < 1) {
    //         setPostcodeError(true)
    //         SweetAlert.fire({
    //           imageUrl: '/assets/error-icon.png',
    //           imageHeight: '100px',
    //           title: 'Oops...',
    //           text: 'Your postcode is not valid, please input valid postcode.',
    //           confirmButtonColor: '#0099FF',
    //         });
    //         return
    //       } else {
    //         defaultFilters['latitude'] = isPostcodeValid.position.latitude
    //         defaultFilters['longitude'] = isPostcodeValid.position.longitude
    //       }
    //     }
    //   }
    // }
    dispatch(setFilters(defaultFilters));
    let filterParams = {};
    if (defaultFilters.level) filterParams.level = level;
    // if (defaultFilters.isOnline > -1) filterParams.option = defaultFilters.isOnline;
    // if (defaultFilters.isOnline === 0) {
    //   filterParams.distance = defaultFilters.distance;
    // }
    dispatch(setFilters(defaultFilters));
    navigate("/search");
  };

  const verify = useCallback(
    async (token) => {
      createAxiosInstance()
        .post(`/api/auth/verify`, { token })
        .then((response) => {
          SweetAlert.fire({
            // position: 'top-end',
            imageUrl: "/assets/success-icon.png",
            imageHeight: "100px",
            title: "Success!",
            html: "Your email has been verified",
            // text: ' I will respond to you as soon as I can.   ---Meera Vasudeva---',
            width: 600,
            confirmButtonColor: "#0099FF",
            customClass: {
              icon: "no-before-icon",
            },
          });
          // dispatch(setUser(response.data.userInfo))
          // setToken(response.data.token)
        })
        .catch((err) => {
          console.log(err);
          showErrorMessage(
            err.response?.data?.message || "Internal server error."
          );
        })
        .finally(() => navigate("/signin"));
    },
    [dispatch, navigate]
  );

  const locationUrl = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(locationUrl.search);
    const verifyToken = params.get("verify");
    if (verifyToken) {
      verify(verifyToken);
    }
  }, [locationUrl, verify]);

  let bannerImage = "/assets/images/banner-md.jpg";
  if (config["class-name"] ==='english') {
    bannerImage = "/assets/images/banner-ed.webp";
  }
  if (config["class-name"] ==='science') {
    bannerImage = "/assets/images/banner-sc.webp";
  }

  const closeTour = () => {
    setIsTourOpen(false);
    createAxiosInstance()
      .post("/api/auth/tour-completed", { page: "home" })
      .then(({ data }) => {
        dispatch(syncDatabaseUser());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (mainUser.userId) {
      const isTourDone = mainUser.pagesVisited.includes("home");
      if (!isTourDone) {
        setIsTourOpen(true);
      }
    }
  }, [mainUser]);

  return (
    <div className="home-container">
      <div className={`main-card ${config["class-name"]}`}>
        <div className="search-card">
          <div className="main-title">{config["home-title"]}</div>
          <div>
            <div className="sub-title">{config["home-subtitle"]}</div>
            <UnderCurve />
          </div>
          <div className="search-box">
            <FormControl className="select">
              <InputLabel id="maths-level" className="label">
                {" "}
                {config["filter-level-label"]}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={level || " "}
                label="Maths Level"
                onChange={handleLevelChange}
              >
                {Object.entries(config["home-level-values"]).map(
                  ([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            {/* <FormControl className='select'>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location < 0 ? '' : location}
                label="Location"
                onChange={handleLocationChange}
              >
                <MenuItem value={0}>Online</MenuItem>
                <MenuItem value={1}>Current Location</MenuItem>
                <MenuItem value={2}>Within 10 miles</MenuItem>
                <MenuItem value={3}>Within 20 miles</MenuItem>
                <MenuItem value={4}>Within 30 miles</MenuItem>
              </Select>
            </FormControl>
            {parseInt(location) > 0 && <FormControl className='select'>
              <TextField label="Postcode" variant="outlined" placeholder='Your postcode' value={postcode} onChange={(e) => setPostcode(e.target.value)} error={postcodeError} />
            </FormControl>} */}
            <Button
              variant="contained"
              className="button"
              color="secondary"
              onClick={search}
            >
              Search
            </Button>
            <Button
              variant="contained"
              className="button"
              color="secondary"
              onClick={courses}
            >
              Courses
            </Button>
          </div>
          <div className={`levels-box ${config["primary-text-colour-class"]}`}>
            <pre>11+ Primary Secondary GCSE A Level University</pre>
          </div>
        </div>
        <div className="container">
          <div className="image-box">
            <img
              src={bannerImage}
              className="students-img"
              alt="students"
              srcSet=""
            />
          </div>
        </div>
      </div>
      <div className="content-row-wrapper">
        <h2 className="content-row-title">
          “Quality over Quantity” is our Mantra
        </h2>
        <ContentRows />
      </div>
      <AcrossCard />
      <Tour
        startAt={0}
        steps={isGuest ? homeStepsForGuest : homeStepsForTutor}
        showNavigation={true}
        onRequestClose={closeTour}
        rounded={15}
        isOpen={isTourOpen}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        lastStepNextButton={<Button size={"small"}>Finish</Button>}
        showNumber={false}
      />
    </div>
  );
};

export default Home;
