import React from "react";
import "./HowItWorks.css";
import { ReactComponent as Curve } from "../../assets/images/undercurve.tsx";

const HowItWorks = () => {
  return (
    <>
      <div className="howitworks-container">
        <div className="howitworks-heading-box">
          <div className="page-heading">
            <h1>How It Works</h1>
            <Curve style={{ maxWidth: "100%" }} />
          </div>
        </div>

        <div className="howitworks-bottom-box">
          <div className="howitworks-row">
            <div className="howitworks-left-col">
              <h4>For Students and Parents</h4>
              <h2>Find a Tutor</h2>
              <p>
                Our platform makes it simple to connect with the best maths
                tutors near you for Free. Follow these steps:
              </p>
              <ol>
                <li>
                  <strong>Search:</strong> Use the search bar to enter your
                  location, level of study (e.g., GCSE, A-Level, etc.), and
                  specific requirements.
                </li>
                <li>
                  <strong>Browse:</strong> Review detailed tutor profiles that
                  include qualifications, experience, availability, and hourly
                  rates.
                </li>
                <li>
                  <strong>Filter Results:</strong> Narrow down your choices with
                  advanced filters, such as online/offline options or specific
                  specialisations (e.g., algebra, calculus).
                </li>
              </ol>

              <h2>Connect with a Tutor</h2>
              <p>Once you’ve found the ideal tutor:</p>
              <ol>
                <li>
                  <strong>Contact:</strong> Send a message directly through the
                  platform to discuss your needs and availability.
                </li>
                <li>
                  <strong>Book a Session:</strong> Agree on a time and place for
                  your first session – whether online or in person – completely
                  free.
                </li>
              </ol>
            </div>

            <div className="howitworks-right-col">
              <h4>For Tutors</h4>
              <h2>Create Your Profile</h2>
              <p>Join Maths Directory and showcase your skills by:</p>
              <ol>
                <li>
                  <strong>Signing Up:</strong> Complete a quick registration
                  process.
                </li>
                <li>
                  <strong>Building Your Profile:</strong> Add your teaching
                  qualifications, experience, areas of expertise, hourly rates,
                  and availability. You can also add your own social links as
                  well (website, FB page etc.).
                </li>
                <li>
                  <strong>Affordable Pricing:</strong> It only costs you £2 per
                  month! That’s cheaper than a cup of coffee or tea.
                </li>
              </ol>

              <h2>Market your course/classes</h2>
              <p>
                <strong>Courses to Run:</strong> You can advertise any courses
                you offer, such as
                <ul>
                  <li>Masterclasses</li>
                  <li>Group tutoring sessions</li>
                  <li>Workshops</li>
                </ul>
              </p>

              <h4>We Handle the Advertising & Marketing</h4>
              <p>
                Once your profile is live, we take care of the heavy lifting.
                Maths Directory handles all the advertising and marketing
                efforts to make sure your profile gets noticed. Through our
                targeted campaigns, we ensure you reach the right audience.
              </p>
              <p>
                In our large active dedicated Facebook group, we actively
                encourage parents to use the directories, helping to drive
                parent traffic to this platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
