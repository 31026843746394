import React,{createContext, useState} from "react";

export const CreateCourseContext= createContext();


export const CourseContext = ({children}) =>{
    const [ sendReq, setsendReq ] = useState(false);
    const [coursePrice, setCoursePrice]  = useState('')
    return(
     <CreateCourseContext.Provider
     value={{ 
        sendReq,
        setsendReq,
        coursePrice,
        setCoursePrice,
     }}
     >
        {children}
     </CreateCourseContext.Provider>
    )
};

